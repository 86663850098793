.eclipse {
    background: var(--primary-2);
    position: fixed;
    border-radius: 50%;
    z-index:-10;
}


    .eclipse.small {
        right: 0;
        height: 20vh;
        width: 20vh;
        transform: translate(31%, -31%);
    }

    .eclipse.min {
        right: 0;
        height: 15vh;
        width: 15vh;
        transform: translate(50%, 50%);
    }



.eclipse.large {
    height: 95vh;
    width: 95vh;
    transform: translate(-9%, -27%);
}

.eclipse.middle {
    height: 50vh;
    width: 50vh;
    transform: translate(-50%, 51%);
}


@media (min-aspect-ratio:1.2) and (max-aspect-ratio:1.7) {
    .eclipse.large {
        height: 95vh;
        width: 95vh;
        transform: translate(-30%, -27%);
    }

    .eclipse.middle {
        height: 50vh;
        width: 50vh;
        transform: translate(-70%, 51%);
    }
}

@media (max-aspect-ratio:1.2) {
    .eclipse.large {
        left: 0;
        top: 0;
        height: 70vh;
        width: 100vw;
        border-radius: 0%;
        transform: translate(0, 0);
    }

    .eclipse.middle {
        display:none;
    }

    .eclipse.small {
        display: none;
    }

    .eclipse.min {
        display: none;
    }
}

@keyframes eclipse-disappear{
    0%{
        opacity:1;
    }

    100% {
        opacity: 0;
    }
}

.eclipse-disappear {
    animation: eclipse-disappear 0.5s 1 forwards;
}

@keyframes eclipse-to-bar {
    0% {
        
    }

    100% {
        left: 0;
        top: 0;
        width: 100vw;
        height: 6vh;
        transform: translate(0, 0);
        border-radius: 0%;
    }
}

.eclipse-to-bar {
    animation: eclipse-to-bar 0.5s 1 forwards;
}