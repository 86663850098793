:root {
    --expand-button-start: translate(2vmin, -20vmin);
    --expand-animation-time: 0.5s;
    --expand-animation-style: ease-out;
    --expand-start-opacity: 0.1
}


.expand-center {
    position: fixed;
    top: 30vh;
    left: 46vh;
}


@media (min-aspect-ratio:1.2) and (max-aspect-ratio:1.7) {

    .expand-center {
        left: 32vh;
    }
}

@media (max-aspect-ratio:1.2) {

    .expand-center {
        display: none;
    }
}

.expand-center .expand-button {
    position: absolute;
    width: 8vmin;
    height: 8vmin;
    transform: var(--expand-button-start);
}

    .expand-center .expand-button .icon {
        color: var(--silver);
        position: absolute;
        top: -50%;
        left: -50%;
        height: 100%;
        width: 100%;
        cursor: pointer;
        transform-origin: bottom center;
        animation: juggle 3s 1s infinite linear;
    }

.expand-center .contract-button {
    position: absolute;
    width: 5vmin;
    height: 5vmin;
    transform: var(--expand-button-start);
}

    .expand-center .contract-button .icon {
        color: var(--silver);
        position: absolute;
        top: -10%;
        left: -50%;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }


.expand-center .item {
    position: absolute;
    width: 6vmin;
    height: 6vmin;
}

    .expand-center .item.java {
        width: 8vmin;
        height: 8vmin;
    }

    .expand-center .item .icon {
        color: var(--silver);
        position: absolute;
        top: -50%;
        left: -50%;
        width: 100%;
        height: 100%;
        animation: appear var(--expand-animation-time) 1 var(--expand-animation-style) forwards;
    }

    .expand-center .item.contract .icon {
        animation: appear var(--expand-animation-time) 1 var(--expand-animation-style) reverse forwards;
    }



@keyframes appear {
    0% {
        transform: scale(0);
        opacity: var(--expand-start-opacity);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes juggle {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(-15deg);
    }

    10% {
        transform: rotate(15deg);
    }

    15% {
        transform: rotate(-15deg);
    }

    20% {
        transform: rotate(15deg);
    }

    30%, 100% {
        transform: rotate(0deg);
    }
}
