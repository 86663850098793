.portfolio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

    .portfolio .works {
        position: relative;
        width: 80%;
        min-height: 50%;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

.works .title {
    color: var(--primary-3);
    font-family: Bad Script;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

@media (max-aspect-ratio:1.2) {

    .works .title {
        text-align:center;
    }
}

    

    .works .card-list {
        position: relative;
        top: 2vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2vh;
        width: 100%;
    }

.works .quote {
    position: relative;
    top: 5vh;
    text-align: right;
    font-family: 'Nanum Pen Script';
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}