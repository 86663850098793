.clickable-block {
    background-color: var(--primary-3-half);
    position: relative;
    width: 45%;
    border-radius: 18px;
    display: felx;
    flex-direction: column;
    text-decoration: none;
}


    .clickable-block .title {
        color: var(--white);
        font-family: "Crimson Pro";
        font-size: 3.4vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin: 1vh;
    }

    .clickable-block .body {
        color: var(--silver);
        font-family: "Crimson Pro";
        font-size: 2.5vh;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin: 1vh;
    }

        .clickable-block .body.clamp {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .clickable-block .body.viewmore {
            color: var(--primary-1);
            font-weight: 600;
            margin-bottom: 2vh;
        }

    .clickable-block:hover {
        background-color: var(--primary-3);
        box-shadow: 0px 4px 4px 0px black;
    }

@media (max-aspect-ratio:1.2) {
    .clickable-block {
        width: 100%;
    }

        .clickable-block .title {
            text-align: center;
        }

        .clickable-block .body {
            display: none;
        }

            .clickable-block .body.clamp {
                display: none;
            }
}
