.avatar {
    position: fixed;
    bottom: 0;
    left: 28vh;
    z-index: -1;
}

    .avatar img {
        width: auto;
        height: 80vh;
    }


@media (min-aspect-ratio:1.2) and (max-aspect-ratio:1.7) {
    .avatar {
        left: 14vh;
    }
}

@media (max-aspect-ratio:1.2) {
    .avatar {
        left: auto;
        right: 0;
    }

        .avatar img {
            height: 76vh;
        }
}
