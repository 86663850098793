.home-page {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.logo {
    position: fixed;
    top: 2vh;
    left: 2vh;
    z-index: 999;
    width: 150px;
}

@media (max-aspect-ratio:1.2) {
    .home-page .logo {
        top: 67vh;
    }
}

.arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100px;
    transform: translate(-50%, -50%);
    animation: moveArrow 2s ease-in-out infinite;
}

@media (max-aspect-ratio:1.2) {
    .arrow {
        width: 50px;
    }
}

@keyframes moveArrow {
    0% {
        transform: translate(-50%, 0);
    }

    50% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: translate(-50%, 0);
    }
}
