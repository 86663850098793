:root {
    --accent-1: rgba(255, 178, 64, 1);
    --accent-1-half: rgba(255, 178, 64, 0.5);
    --accent-2: rgba(217, 177, 38, 1);
    --accent-2-half: rgba(217, 177, 38, 0.5);
    --accent-3: rgba(189, 170, 0, 1);
    --accent-3-half: rgba(189, 170, 0, 0.5);
    --black: rgba(0, 0, 0, 1);
    --black-half: rgba(0, 0, 0, 0.5);
    --grey: rgba(164, 164, 164, 1);
    --grey-half: rgba(164, 164, 164, 0.8);
    --primary-1: rgba(52, 134, 208, 1);
    --primary-1-half: rgba(151, 206, 255, 0.8);
    --primary-2: linear-gradient( 180deg, rgb(51.45, 28.69, 191.25) 0%, rgb(6.09, 108.55, 182.75) 100% );
    --primary-3: rgba(21, 66, 153, 1);
    --primary-3-half: rgba(67, 127, 160, 0.75);
    --red: rgba(222, 29, 29, 1);
    --secondary-1: rgba(175, 208, 163, 1);
    --secondary-1-half: rgba(175, 208, 163, 0.5);
    --secondary-2: rgba(115, 154, 125, 1);
    --secondary-2-half: rgba(115, 154, 125, 0.5);
    --secondary-3: rgba(54, 87, 63, 1);
    --secondary-3-half: rgba(54, 87, 63, 0.5);
    --silver: rgba(238, 238, 238, 1);
    --silver-half: rgba(232, 232, 232, 0.95);
    --silvergrey: rgba(220, 220, 220, 1);
    --white: rgba(255, 255, 255, 1);
    --white-half: rgba(255, 255, 255, 0.5);
    --silver-grey: linear-gradient(90deg, rgba(238, 238, 238, 238) 0%, rgba(225, 225, 225, 1) 100%);
}
