.introduction {
    position: fixed;
    top: 10vh;
    right: 0;
    width: 44vw;
    height: 90vh;
}

@media (min-aspect-ratio:1.2) and (max-aspect-ratio:1.7) {
    .introduction {
        width: 42vw;
    }
}

@media (max-aspect-ratio:1.2) {
    .introduction {
        top: 5vh;
        left: 0;
        width: 70vw;
        height: 92vh;
    }
}

.shadow {
    color: var(--silver);
}

.name-frame {
    position: absolute;
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media (max-aspect-ratio:1.2) {
    .name-frame {
        right: 0;
    }
}

.name-frame .row {
    position: relative;
    width: 80%;
    height: 40%;
}


.name-frame .shadow {
    font-family: Noto Serif SC;
    font-size: 10vmin;
    font-weight: 400;
    position: absolute;
    left: -4vmin;
    top: -3vmin;
    z-index: -1;
}

@media (max-aspect-ratio:1.2) {
    .name-frame .shadow {
        display: none;
    }
}

.name-frame .name {
    color: var(--primary-3);
    font-family: "Crimson Pro", sans-serif;
    font-size: 10vh;
    font-weight: 500;
    letter-spacing: 0.3vmin;
    position: absolute;
}

@media (max-aspect-ratio:1.2) {
    .name-frame .name {
        color: var(--silver);
    }
}

.name-frame .icon-group {
    position: absolute;
    top: 6.5vh;
    left: 23vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

@media (max-aspect-ratio:1.2) {
    .name-frame .icon-group {
        top: 15vh;
        left: 0;
    }
}

.name-frame .icon {
    color: var(--primary-3);
    margin-right: 1vmin;
}

@media (max-aspect-ratio:1.2) {
    .name-frame .icon {
        color: var(--silver);
    }
}

.name-frame .icon.linkedin {
    width: 3vh;
    height: 3vh;
}

.name-frame .icon.email {
    width: 2.7vh;
    height: 3vh;
}

.titles {
    position: absolute;
    top: 35%;
    right: 10%;
    max-width: 120%;
    font-family: 'Nanum Pen Script';
    font-size: 6vmin;
    font-style: italic;
    font-weight: 400;
    text-align: right;
}

    .titles .title {
        position: relative;
        top: -7vh;
        color: var(--primary-3);
    }

    .titles .shadow {
        position: relative;
        top: -3vh;
        right: 18vh;
        z-index: -1;
    }

    .titles.small {
        position: absolute;
        top: 42%;
        left: 5%;
        width: 50%;
        height: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

        .titles.small .row {
            display: flex;
            flex-direction: row;
            gap: 1vh;
        }

        .titles.small .title {
            color: var(--silver);
            position: static;
        }

        .titles.small .shadow {
            color: var(--grey);
            position: static;
        }

.seperate-lines {
    display: none;
}

@media (min-aspect-ratio:1.2) {
    .seperate-lines {
        position: absolute;
        top: 54%;
        right: 0;
        width: 100%;
        height: 5%;
    }

        .seperate-lines .shadow {
            background-color: var(--silver);
            border-radius: 32px 0px 0px 32px;
            position: absolute;
            width: 105%;
            height: 50%;
            left: -5%;
        }

        .seperate-lines .line {
            background: var(--primary-2);
            border-radius: 32px 0px 0px 32px;
            position: absolute;
            width: 100%;
            height: 50%;
            left: 0;
            top: 50%;
        }
}

.command-line {
    position: absolute;
    background: var(--primary-2);
    left: 0;
    top: 48%;
    width: 92%;
    height: 18%;
    /*    border: 2px solid black;*/
    border-radius: 10px;
    color: var(--white);
    font-family: "Pixelify Sans";
    font-size: 3vh;
    font-style: normal;
    font-weight: 400;
    text-align: left;
}

    .command-line .prompt {
        position: absolute;
        left: 2vh;
        top: 1.2vh;
        color: var(--white);
    }

    .command-line .typewriter {
        position: absolute;
        left: 6vh;
        top: 1vh;
        width: 85%;
    }

@media (min-aspect-ratio:1.2) and (max-aspect-ratio:1.7) {
    .command-line {
        font-size: 2.6vh;
        line-height: 1.2;
    }
}

.command-line .blinking {
    animation: flash 2s infinite;
}



@media (max-aspect-ratio:1.2) {
    .command-line {
        display: none;
    }
}

.links {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3%;
}

@media (max-aspect-ratio:1.2) {
    .links {
        left: 10%;
        top: 75%;
        width: 50%;
        flex-direction: column;
    }
}
