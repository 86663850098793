.project-card-link {
    text-decoration: none;
    color: inherit;
}


.project-card {
    box-sizing: border-box;
    background: var(--silver-grey);
    position: relative;
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    padding: 1%;
    border-radius: 15px;
    overflow: hidden;
}


@media (max-aspect-ratio:1.2) {
    .project-card {
        min-width: 50%;
        flex-direction: column;
        height: auto;
        padding: 5%;
    }
}


.project-card:hover {
    background: var(--silvergrey);
    box-shadow: 0px 4px 4px 0px gray;
}

img.project-img {
    height: 100%;
    width: 20%;
    flex-shrink: 0;
    border-radius: 8px;
}

@media (max-aspect-ratio:1.2) {
    img.project-img {
        width: 100%;
        flex-direction: column;
        height: auto;
    }
}


.project-right-layout {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vmin;
}

.project-title {
    color: var(--primary-3);
    font-family: Crimson Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.project-labels {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.project-label {
    display: inline-flex;
    padding: 4px 10px;
    align-items: center;
    border-radius: 10px;
    color: var(--white);
}

    .project-label.green {
        background: var(--secondary-2);
    }
    .project-label.blue {
        background: var(--primary-3);
    }
    .project-label.red {
        background: var(--red);
    }
    .project-label.orange {
        background: var(--accent-1);
    }
    .project-label.gray {
        background: var(--grey);
    }

.project-description {
    /* P1 */
    font-family: Dosis;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
}

@media (max-aspect-ratio:1.7) {
    .project-description {
        font-size: 15px;
    }
}