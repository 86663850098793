.hidden {
    visibility: hidden;
}

.bouncein {
    animation: bounceInRight 1s 1;
}

.fadein {
    animation: fadeIn 1s 1;
}

.zoomin {
    animation: zoomInUp 1s 1;
}

.bouncein-right {
    animation: bounceInRight 1s 1;
}

.bouncein-left {
    animation: bounceInLeft 1s 1;
}

.fadein-right {
    animation: fadeInRight 0.8s 1;
}

.fadein-left {
    animation: fadeInLeft 1s 1;
}

.fadein-up {
    animation: fadeInUp 1s 1;
}

.fadeout-up {
    animation: fadeOutUpBig 1s 1;
}