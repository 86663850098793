.about {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

    .about .card {
        position: relative;
        width: 80%;
        border: solid black;
        border-radius: 18px;
        padding: 5%;
    }

    .about .about-me {
        margin-top: 15vh;
        margin-bottom: 10vh;
    }

    .about .story {
        margin-bottom: 12vh;
    }

    .about .title {
        color: var(--primary-3);
        font-family: 'Oleo Script';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

@media (max-aspect-ratio:1.2) {
    .about .title {
        text-align: center;
    }
}

.about p {
    font-family: Crimson Pro;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.about-me p {
    font-family: Crimson Pro;
    font-size: 25px;
    line-height: 50px;
}

    .about-me p .high-light {
        font-family: Bad Script;
        color: var(--primary-3);
        font-size: 40px;
        margin-left: 20px;
        margin-right: 10px;
    }

    .about .photo-frame {
        height: 20vh;
        float: right;
        margin-right: 40px;
        margin-left: 40px;
        margin-bottom: 20px;
    }

        .about .photo-frame img {
            position: relative;
            top: -70%;
            height:40vh
        }

@media (max-aspect-ratio:1.2) {
    .about .photo-frame {
        height: auto;
        float: none;
        text-align: center;
    }

        .about .photo-frame img {
            top: 0;
        }

    .about .card {
        width:70%;
    }

    .about-me p .high-light {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 30px;
    }
}

.story p {
    font-size: 18px;
}

    .story p::first-letter {
        font-size: 32px;
    }

.about .quote {
    text-align: right;
    font-family: 'Nanum Pen Script';
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    align-self: end;
}
