.navbar {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100vw;
    height: 6vh;
    background: var(--primary-2);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 2vh;
}

    .navbar .menu {
        margin-right:4vh;
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:2vmin;
    }

    .navbar .link {
        color: var(--white);
        font-family: Gabarito;
        font-size: 15px;
        text-decoration: none;
    }

@media (max-aspect-ratio:1.2){
    .navbar .link {
        font-size: 12px;
    }
}

.navbar .link.disable {
    color: var(--grey);
    cursor: default;
}